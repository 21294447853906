import React from "react"

const Download = ({
  title,
  file: {
    file: {
      url,
      fileName,
      details: { size },
    },
  },
}) => (
  <div class="download">
    <div class="download__text">
      <h3>{title}</h3>
      <a href={url} download>
        {fileName} ({Math.ceil(size / 1000)} KB)
      </a>
    </div>
  </div>
)

export default Download
