import React from "react"

const Photo = ({
  photo: {
    sizes: { src, srcSet },
    title,
    description,
  },
}) => (
  <div class="photo">
    <img src={src} srcSet={srcSet} title={title} alt={description} />
  </div>
)

export default Photo
