import React from "react"

const Video = ({ url }) => (
  <div class="video">
    <iframe
      width="1440"
      height="762"
      src={url.replace(".com/watch?v=", "-nocookie.com/embed/")}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
    ></iframe>
  </div>
)

export default Video
