import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Content from "../components/content"
import aboutImage from "../images/about.jpg"

const AboutDesign = ({ children }) => (
  <div id="about">
    <img
      className="about__photo"
      src={aboutImage}
      alt="Iskandar playing the violine"
    />
    <section>{children}</section>
  </div>
)

const MediaDesign = ({ children }) => (
  <div id="media">
    <section>{children}</section>
  </div>
)

const Page = ({
  data: {
    page,
    parentPage,
    contacts: { edges: contacts },
  },
  location,
}) => {
  const navigation = [parentPage || page]
  if (navigation[0].subPages) {
    navigation[0].subPages.forEach(subPage => navigation.push(subPage))
  }
  const Design = page.design === "about" ? AboutDesign : MediaDesign
  return (
    <Layout>
      <SEO title={page.title} />
      <div class="page">
        <Design>
          {navigation.length > 1 && (
            <ol className="page__navigation">
              {navigation.map(({ slug, title }) => (
                <li>
                  {location.pathname === `/${slug}` ? (
                    title
                  ) : (
                    <Link to={`/${slug}`}>{title}</Link>
                  )}
                </li>
              ))}
            </ol>
          )}
          <h2>{page.title}</h2>
          <Content content={page.content} />
        </Design>
      </div>
      <Footer contacts={contacts} />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      id
      content {
        ... on ContentfulQuotation {
          id
          date
          sourceName
          quotation {
            quotation
          }
          internal {
            type
          }
        }
        ... on ContentfulText {
          id
          text {
            text
          }
          internal {
            type
          }
        }
        ... on ContentfulMusic {
          id
          amazonURL
          spotifyURL
          title
          previewImage {
            sizes {
              srcSet
              src
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulVideo {
          id
          url
          internal {
            type
          }
        }
        ... on ContentfulPhoto {
          id
          photo {
            sizes {
              src
              srcSet
            }
            description
            title
          }
          internal {
            type
          }
        }
        ... on ContentfulDownload {
          id
          title
          file {
            file {
              url
              fileName
              details {
                size
              }
            }
          }
          internal {
            type
          }
        }
      }
      lang
      slug
      title
      design
      subPages {
        slug
        title
      }
    }
    parentPage: contentfulPage(subPages: { elemMatch: { id: { eq: $id } } }) {
      title
      slug
      subPages {
        slug
        title
      }
    }
    contacts: allContentfulContact {
      edges {
        node {
          id
          title
          contact {
            contact
          }
        }
      }
    }
  }
`
