import React from "react"

const LinkIfAvailable = ({ url, children }) =>
  url ? <a href={url}>{children}</a> : children

const Music = ({
  title,
  amazonURL,
  spotifyURL,
  previewImage: {
    sizes: { src, srcSet },
  },
}) => (
  <div class="music">
    <LinkIfAvailable url={amazonURL || spotifyURL}>
      <img src={src} srcSet={srcSet} />
    </LinkIfAvailable>
    <div class="music__text">
      <h3>{title}</h3>
      {amazonURL && (
        <a href={amazonURL} className="music__amazon">
          Auf Amazon kaufen
        </a>
      )}
      {spotifyURL && (
        <a href={spotifyURL} className="music__spotify">
          Auf Spotify hören
        </a>
      )}
    </div>
  </div>
)

export default Music
