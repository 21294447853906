import React from "react"
import moment from "moment"

import RichText from "./rich-text"

const Quotation = ({ date, sourceName, quotation: { quotation } }) => (
  <div class="quotation">
    <blockquote>
      <RichText source={quotation} />
    </blockquote>
    <cite>
      {sourceName}, {moment(date, "YYYY-MM-DD").format("MMMM YYYY")}
    </cite>
  </div>
)

export default Quotation
