import React from "react"

import Quotation from "../components/quotation"
import Text from "../components/text"
import Music from "../components/music"
import Video from "../components/video"
import Photo from "../components/photo"
import Download from "../components/download"

const Content = ({ content }) =>
  content &&
  content.map(content => {
    if (content.internal)
      switch (content.internal.type) {
        case "ContentfulQuotation":
          return <Quotation {...content} />
        case "ContentfulText":
          return <Text {...content} />
        case "ContentfulMusic":
          return <Music {...content} />
        case "ContentfulVideo":
          return <Video {...content} />
        case "ContentfulPhoto":
          return <Photo {...content} />
        case "ContentfulDownload":
          return <Download {...content} />
      }
  })

export default Content
