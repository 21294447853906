import React from "react"

import RichText from "./rich-text"

const Text = ({ text: { text } }) => (
  <div class="text">
    <RichText source={text} />
  </div>
)

export default Text
